import {BUCodes} from '@/constants/user.js';
import {ZoneCodes} from '@/constants/zone.js';

export function getZoneCodeByBU(businessUnitCode) {
    if (businessUnitCode === BUCodes.JP || businessUnitCode === BUCodes.CH) {
        return ZoneCodes.OUS;
    }
    if (businessUnitCode === BUCodes.US) {
        return ZoneCodes.US;
    }
}
